import React, { useState, useEffect } from "react";
import {Link,useParams} from "react-router-dom";
import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
import https from 'https';

import axios from 'axios';

export function plus(a, b)
{
  return a + b;
}
export function getApi(endpoint,token,customEndPoint=false)
{
  const get = async () => {
    const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': token}
        };

    if (customEndPoint==true)
    {
       endpoint = endpoint;
    }
    else
    {
      endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
    }

    const response = await fetch(endpoint,requestOptions);
    return await response.json();
  };
  return get();
}
export function getApiCustomer(endpoint,token,customEndPoint=false)
{
  const get = async () => {
    const requestOptions = {
            method: 'GET'
        };

    if (customEndPoint==true)
    {
       endpoint = endpoint;
    }
    else
    {
      endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
    }

    const response = await fetch(endpoint,requestOptions);
    return await response.json();
  };
  return get();
}
export function getStats(endpoint,accessToken=null)
{
  return getApiCustomer(endpoint,accessToken,true);
}
export function getApiNormal(endpoint,token)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': token}
        };
    const response = await fetch("https://wms.digitag.cl/4nv2/api/v1/"+endpoint,requestOptions);
    return await response;
  };
  return getProducts();
}
export function getFileJSON(endpoint)
{
  const getFile = async () => {
    const requestOptions = {
            method: 'GET',
            agent: new https.Agent({
              rejectUnauthorized: false
            })
            // headers: { 'Author': 'Ricardo Rebolledo'}
        };
    const response = await fetch(endpoint,requestOptions);
    return await response.json();
  };
  return getFile();
}
export function putApi(endpoint,token,data)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'PUT',
            body: data,
            headers: { 'Authorization': token}
        };
    const response = await fetch("https://wms.digitag.cl/4nv2/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function postApi(endpoint,token,data,customEndPoint=false)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Authorization': token}
        };
    if (customEndPoint==true)
    {
       endpoint = endpoint;
    }
    else
    {
      endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
    }

    const response = await fetch(endpoint,requestOptions).then(function(response) {
        return response.json();
    })


    // return await response.json();
  };
  return getProducts();
}

export async function postToPrint(endpoint,token,data,customEndPoint=false)
{
  const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Authorization': token},
          mode: 'no-cors'
      };
  if (customEndPoint==true)
  {
     endpoint = endpoint;
  }
  else
  {
    endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
  }
  
  return await new Promise(async (resolve, reject) => {
                  await fetch(endpoint,requestOptions)
                    .then(resolve)
                    .catch(reject)
                });

}
export function putMap(accessToken,data)
{
  return putApi("admin/fields/update/"+accessToken,accessToken,data);
}
export async function ApiSendPVP(typeKey,key,pvp,date_from,date_to,endPoint=null)
{
  const data = {date_to:date_to,date_from:date_from,pvp:pvp,key:key,typeKey:typeKey};
  const headers = {
          method: 'POST',
          body: JSON.stringify(data),
          // headers: { 'Authorization': 'token'},
          mode: 'no-cors'
      };
  // const response = await axios.post(endPoint,data,headers);
  // return response;
  return await postToPrint(endPoint,'MYTOKENAUTHORIZA',data,true);
}
export function ApiSendSpecificPrice(typeKey,key,typeDiscount,discount,date_from,date_to,endPoint=null)
{
  return postToPrint(endPoint,'MYTOKENAUTHORIZA',{date_to:date_to,date_from:date_from,discount:discount,type_discount:typeDiscount,key:key,typeKey:typeKey},true);
}
export function printLabelApi(idOrder)
{
  return postToPrint("http://10.0.8.15/print/label.php?id_order="+idOrder,"","",true);
}
export function printGuiaApi(idOrder)
{
  return postToPrint("http://10.0.8.15/print/guia.php?id_order="+idOrder,"","",true);
}

export function Authentication(user,password)
{
  return getApi("login/user/"+user+"/"+password,null);
}

export function noDataLoading()
{
  return (<>No information available</>)

}



export function getOrderPendingToPrint(from,to,idWarehouse,page,accessToken)
{
  return getApi("orders/pending/"+page+"/"+from+"/"+to+"/"+idWarehouse+"",accessToken);
}
export function getOrders(page,accessToken)
{
  return getApi("orders/"+page+"/"+accessToken,accessToken);
}