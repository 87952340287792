import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Navbar = ({currentPrinter,totalPrinter}) => 
{
	return (
		<>
		<div id="layoutSidenav_nav">
		    <nav className="sidenav shadow-right sidenav-light">
		        <div className="sidenav-menu">
		            <div className="nav accordion" id="accordionSidenav">
		                <div className="sidenav-menu-heading">Core</div>
		                <Link className="nav-link collapsed" to="/dashboard" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Dashboard
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="collapse" id="collapseDashboards" data-bs-parent="#accordionSidenav">
		                    <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
		                        <a className="nav-link" href="dashboard-1.html">
		                            Default
		                            <span className="badge bg-primary-soft text-primary ms-auto">Updated</span>
		                        </a>
		                        <a className="nav-link" href="dashboard-2.html">Multipurpose</a>
		                        <a className="nav-link" href="dashboard-3.html">Affiliate</a>
		                    </nav>
		                </div>
		                <div className="sidenav-menu-heading">PRODUCTOS</div>
		                <Link className="nav-link collapsed" to="/products/status" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Activar/Desactivar
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">PRECIOS</div>
		                <Link className="nav-link collapsed" to="/orders/pending" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Upload PVP
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/products/discount" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Upload Descuentos
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/discounts/delete" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Eliminar Descuentos
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">CATEGORIAS</div>
		                <Link className="nav-link collapsed" to="/category/assoc" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Asociación de productos
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <Link className="nav-link collapsed" to="/products/categories" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Categorías BG
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">CONFIGURACION</div>
		                <Link className="nav-link collapsed" to="/cache/delete" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Eliminar Cache
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">COLA DE CARGA</div>
		                <a class="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages" href="/orders/pending">
		                	<div class="nav-link-icon"><i data-feather="grid"></i></div>
	                		<span className="currentPrinter" id="currentPrinter">{currentPrinter}</span> / <span className="totalPrinter" id="totalPrinter">{totalPrinter}</span>
	                		<br />
	                		<br />
	                		<span className="pausePrint d-none" id="pausePrint">PAUSE</span>
		                </a>
		            </div>
		        </div>
		        <div className="sidenav-footer">
		            <div className="sidenav-footer-content">
		                <div className="sidenav-footer-subtitle">Logged in as:</div>
		                <div className="sidenav-footer-title">{cookies.get('username')}
		                	<span className="sidebar-logout">
		                		<Link to="/logout">(Logout)</Link>
	                		</span>
	                	</div>
		            </div>
		        </div>
		    </nav>
		</div>
		</>
	)
}
export default Navbar;