import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi } from './Helper.js'
import $ from 'jquery';

/*import '../assets/js/jquery.easing.min.js';*/
/*import '../assets/js/sb-admin-2.min.js';*/
import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

/*global.$ = global.jQuery = $;*/
import "react-loading-skeleton/dist/skeleton.css";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';
// import {Link} from "react-router-dom";

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function App() {
  useEffect(() => {
     document.title = "4N WMS"
  }, []);
  
  let componentMounted = true;
  const [products,setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      setProducts(await getApi("manufacturer/"+cookies.get('public_token')+"/products",cookies.get('public_token')));
      setLoading(false);
      return;
    };
    getProducts();
  }, []);

  const Loading = () => {
    return (
    <>
        <div className="col-md-3">
            <Skeleton height={350}/>
        </div>
        <div className="col-md-3">
            <Skeleton height={350}/>
        </div>
        <div className="col-md-3">
            <Skeleton height={350}/>
        </div>
        <div className="col-md-3">
            <Skeleton height={350}/>
        </div>
    </>
    );
  };

  const ShowProducts = () => {
    return (
      <>
        {products.result.map((product) => {
          return (
            <>
              <div className="col-md-3 mb-4" key="{product.id_product}">
                <div className="card h-100 text-center p-4">
                  <img src={product.id_product} className="card-img-top" alt={product.name} height="150px" />
                  <div className="card-body">
                    <h5 className="card-title mb-0">{plus(1+1)} / {product.name.substring(0,12)}...</h5>
                    <p className="card-text lead fw-bold">
                      ${product.price}
                    </p>
                    <a to={`/products/${product.id_product}`} className="btn btn-outline-dark">
                     x Comprar Ahora
                    </a>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Blank Starter
                                    </h1>
                                    <div className="page-header-subtitle">Use this blank page as a starting point for creating new pages inside your project!</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4">Optional page header content</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  {loading ? <Loading /> : <ShowProducts />}
                  
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default App;
