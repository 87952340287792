import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getProductDetail, getStats, getProductDetailStats } from './Helper.js'
import $ from 'jquery';
import ReactECharts from 'echarts-for-react';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';
// import Category from './Category.js';
import {Link,useParams} from "react-router-dom";

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Dashboard() {
  useEffect(() => {
       document.title = "Product - Scrap.ly Sandbox"
    }, []);
  const parameterPage = useParams();
  const [stats,setStats] = useState(false);
  const [totalProductsAvailable,setTotalProductsAvailable] = useState(true);
  const [totalUrlCustomer,setTotalUrlCustomer] = useState(true);
  const [totalProductsDiscount,setTotalProductsDiscount] = useState(true);
  const [totalWebsitesCompetitor,setTotalWebsitesCompetitor] = useState(true);
  const [totalProductsCompetitor,setTotalProductsCompetitor] = useState(true);
  const [respCall,setResponse] = useState(true);
  const [respStats,setResponseStats] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []

  const axisStats = []
  const val1Stats = []
  const val2Stats = []
  const [maxValueStats,setMaxValueStats] = useState(true);

  const urlAvailables = [
    // {'title':'DEMO 2 / EV ','url':'https://ev.ps.digitag.cl/local/module/prestaclhook/specific_price?getStats&digitagaccess'},
    // {'title':'MYC QA','url':'https://myc.ps.digitag.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'DSCL PROD','url':'https://descorcha.com/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'MYC PROD','url':'https://www.mesaycocina.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    // {'title':'EV QA','url':'https://ev.ps.digitag.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'EV PROD','url':'https://www.elvolcan.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'CORLIMA PROD','url':'https://www.corlima.pe/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'NS PROD','url':'https://www.northstar.cl/module/prestaclhook/specific_price?getStats&digitagaccess&debug'},
    // {'title':'NS QA','url':'https://northstar.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'BG PROD','url':'https://www.bubblegummers.cl/module/prestaclhook/specific_price?getStats&digitagaccess&debug'},
    // {'title':'BG QA','url':'https://www.bubblegummers.cl/module/prestaclhook/specific_price?getStats&digitagaccess'},
    {'title':'WB PROD','url':'https://www.weinbrenner.cl/module/prestaclhook/specific_price?getStats&digitagaccess&debug'}
    // {'title':'WB QA','url':'https://weinbrenner.cl/module/prestaclhook/specific_price'}
  ]

  const hacerPeticiones = async () => {

    let counter = 0;

    const split = urlAvailables

    let arrayStats = [];

    for (const value of urlAvailables) {

      try {

        const dataBody = {typeKey:'reference'};

        const response = await fetch(value.url, {
          method: 'POST', // o 'GET', 'PUT', 'DELETE', etc. según tu caso
          body: JSON.stringify(dataBody), // si necesitas enviar datos en el cuerpo de la solicitud
          // mode: 'no-cors'
          // headers: {
            // 'Content-Type': 'application/json' // ajusta los encabezados según tus necesidades
          // }
        }).then(function (response) {

          // counter = counter + value.length;
          // setStatesPrinter({currentPrinter:counter,totalPrinter:respCall.length})

          if (response.status !== 200)
          {
            return false;
          }

          if (!response.ok) {
            return response.text().then(result => Promise.reject(new Error(result)));
          }

          return response.json();

          // console.log(response.json());

        }).then(function (data) {

          return data;

        })

        console.log(response);

        arrayStats.push(
                        [
                          {header:["Hoy","Ayer","2D","3D","4D","5D","7D","1M"]}
                          ,{body:[response.data[0]['today'],response.data[0]['t1d'],response.data[0]['t2d'],response.data[0]['t3d'],response.data[0]['t4d'],response.data[0]['t5d'],response.data[0]['t7d'],response.data[0]['lastMonth']]}
                          ,value.title
                          ,{body:[response.data[0]['today_total'],response.data[0]['t1d_total'],response.data[0]['t2d_total'],response.data[0]['t3d_total'],response.data[0]['t4d_total'],response.data[0]['t5d_total'],response.data[0]['t7d_total'],response.data[0]['lastMonth_total']]}
                        ]
                      )

        // const data2 = await response.json();
        
        
        // console.log(data2);

      } catch (error) {
        console.error(error);
      }
    }

    setStats(arrayStats)

    setLoading1(false)

  }


  // useEffect(hacerPeticiones,[]);
  // useEffect(() => {
  //     // hacerPeticiones();
  // }); 

  const refreshData = () => 
  {
    hacerPeticiones();
  }
  useEffect(refreshData,[]);
  // {

  //   setLoading1(true)

  //   urlAvailables.map((value,index)=>{

  //       const generateStats = async () => {

  //           let arrayStats = [];
  //           const data = await getStats(value.url)

  //           if (typeof data.data[0] !== "undefined")
  //           {
  //               arrayStats.push(
                                  
  //                                   {header:["Hoy","Ayer","2D","3D","4D","5D","6D","7D","1M"]}
  //                                   ,{body:[data.data[0]['today'],data.data[0]['t1d'],data.data[0]['t2d'],data.data[0]['t3d'],data.data[0]['t4d'],data.data[0]['t5d'],data.data[0]['t6d'],data.data[0]['t7d'],data.data[0]['lastMonth']]}
                                  
  //                             )

  //               setStats(arrayStats)
  //           }
  //       };

  //       generateStats();
  //       setLoading1(false)
  //   });
    
  // };

  // useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
      <div className="row">
          <div className="col-md-4">
            <div className="card mb-4 mb-xl-0">
                <Skeleton height={400}/>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card mb-8 mb-xl-0">
                <Skeleton height={80}/>
                <Skeleton count={30}/>
            </div>
          </div>
      </div>
    </>
    );
  };
  // if (loading) {
  //   return <h1> Data is loading...</h1>
  // }
  function contains(a, obj) {
      for (var i = 0; i < a.length; i++) {
          if (a[i] === obj) {
              return true;
          }
      }
      return false;
  }


  const ShowData = (stats) => {

    if (stats.stats==false || stats.stats == true)
    {
      return (<></>)
    }

    let html = '';

    // for (const value of stats.stats)
    // {
        const options = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
          },
          xAxis: {
            type: 'category',
            data: stats.stats[0]['header']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: stats.stats[1]['body'],
              type: 'bar'
            }
          ]
        };

        const optionsCurrency = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
          },
          xAxis: {
            type: 'category',
            data: stats.stats[0]['header']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: stats.stats[3]['body'],
              type: 'bar'
            }
          ]
        };

        return (
            <div className="row">
              <div className="col-xl-6 col-md-6 mb-4">
                <div className="card mb-4">
                    <div className="card-header">{stats.stats[2]} / QTY</div>
                    <div className="card-body">
                        <div className="chart-area">
                          <ReactECharts option={options} />
                        </div>
                    </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 mb-4">
                <div className="card mb-4">
                    <div className="card-header">{stats.stats[2]} / VENTAS </div>
                    <div className="card-body">
                        <div className="chart-area">
                          <ReactECharts option={optionsCurrency} />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
    // }

    // return html;


  };

  // let html = '';
  // stats.map((value,index)=>{
  //   html += <ShowData stats={value} /> 
  // })

  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar />
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        My Dashboard
                                    </h1>
                                    <div className="page-header-subtitle"></div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4"></div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                  {
                    loading1 ? <Loading/> : (
                                                stats.map((value,index)=>{
                                                  return <ShowData stats={value} /> 
                                                })
                                            )
                  }
                    
                  
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default Dashboard;
