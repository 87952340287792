import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import { plus, getApi, Authentication } from './Helper.js'

import {Link,useParams,BrowserRouter,useNavigate,redirect} from "react-router-dom";


// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Logout() {
  
  const [respCall,setResponse] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
     document.title = "Logout - Scrap.ly Sandbox"
     cookies.remove("public_token");
     cookies.remove("username");

     window.location.replace('/');

  }, []);



  return (

    <>
      
    </>
  );
}

export default Logout;
