import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getOrderPendingToPrint, getOrders, noDataLoading, printLabelApi, printGuiaApi,getFileJSON,ApiSendPVP } from './Helper.js'
import axios from 'axios';

// import { ToastContainer, toast } from 'react-toastify';
// import { Select } from 'react-select';
// import 'react-toastify/dist/ReactToastify.css';


import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function ProductsActive() {

  const parameterPage = useParams();
  const [respPrintDate,setRespPrintDate] = useState(true);
  const [respCall,setResponse] = useState(true);
  const [state,setState] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [website, setWebsite] = useState(true);
  
  // const queryParams = new URLSearchParams(window.location.search)
  // const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []
  const urlAvailables = [
    {'title':'DEMO 1 / DIGITAG','url':'https://digitag.cl/demo.php?specific_price&digitagaccess&enabledisabled'},
    {'title':'DEMO 2 / EV ','url':'https://ev.ps.digitag.cl/local/module/prestaclhook/specific_price&digitagaccess&enabledisabled'},
    
    // {'title':'MYC PROD','url':'https://www.mesaycocina.cl/module/prestaclhook/specific_price?digitagaccess&enabledisabled'},
    
    {'title':'EV PROD','url':'https://www.elvolcan.cl/module/prestaclhook/specific_price?digitagaccess&enabledisabled'},
    // {'title':'CORLIMA PROD','url':'https://www.corlima.pe/module/prestaclhook/specific_price?digitagaccess&enabledisabled'},
    {'title':'NS PROD','url':'https://www.northstar.cl/module/prestaclhook/specific_price?digitagaccess&enabledisabled&debug'},
    
    {'title':'BG PROD','url':'https://www.bubblegummers.cl/module/prestaclhook/specific_price?digitagaccess&enabledisabled&debug'},
    
    {'title':'WB PROD','url':'https://www.weinbrenner.cl/module/prestaclhook/specific_price?digitagaccess&enabledisabled&debug'}
    
  ]

  const [statesPrinter,setStatesPrinter] = useState(true);
  const [statesOptions,setStatesOptions] = useState(true);
  const [currentOrder,setCurrentOrder] = useState(true);
  const [columnPrices,setColumnPVP] = useState(true);
  const [columnKey,setColumnKey] = useState(true);

  useEffect(() => {
    document.title = "Digitag PS Master"
    setLoading1(true);
    setStatesPrinter({currentPrinter: 0,totalPrinter: 0});
    setCurrentOrder({id_order : 0, status : 'pending'});
    setColumnPVP(null);
    setColumnKey(null);
    setLoading1(false);
  }, []);

  // PAUSE PROCESS
  const elInput = document.querySelector('#pausePrint');
  let pause = Promise.resolve();
  const sleep = ms => new Promise(r => setTimeout(r,ms));

  const pauseClick = cb => 
    elInput.addEventListener('click', cb, {once: true});

  function checkForPause() {   
    pauseClick(() => {
      pause = new Promise(resolve => {
        pauseClick(() => { resolve(); checkForPause();});
      });
    });
  }
  // END PAUSE PROCESS


  const refreshURL = url => 
  {
    setWebsite(url);
  }
  const refreshData = () => 
  {

    // console.log(parameterPage);
      const refreshConst = async () => {
        setLoading1(true);
        // setResponse(await getFileJSON('https://cdn.dituci.com/customer/2023/05/22/202305220821581684758118_json2.json'))
        console.log("Hola:71");
        console.log("Hola:71");

        // setLoading1(false);
      };
      refreshConst();
  };
  useEffect(refreshData,[]);

  
  const emptyF = async (p1=null,p2=null,p3=null,p4=null,p5=null,p6=null,p7=null,p8=null) =>
  {
    return;
  }
  

  const printRange = () => {
    
  };

  // state = {
  //       // Initially, no file is selected
  //       selectedFile: null
  //     };
      // On file select (from the pop up)
      const onFileChange = event => {
        // Update the state
        setState({ selectedFile: event.target.files[0] });
      };
      // On file upload (click the upload button)
      const onFileUpload = () => {
        // Create an object of formData
        const formData = new FormData();
        // Update the formData object
        formData.append(
          "file",
          state.selectedFile,
          state.selectedFile.name
        );
        // Details of the uploaded file
        console.log(state.selectedFile);
        // Request made to the backend api
        // Send formData object
        axios.post('https://ev.ps.digitag.cl/vev/api/index.php/convertFile', formData).then((response)=>{
          setLoading1(true);
          setResponse(response.data.data);
          setLoading1(false);
        });
      };

  const fileData = () => {
       
        if (state.selectedFile) {
            
          return (
            <div>
              <h2>File Details:</h2>
              <p>File Name: {state.selectedFile.name}</p>
    
              <p>File Type: {state.selectedFile.type}</p>
    
              <p>
                Last Modified:{" "}
                {state.selectedFile.lastModifiedDate.toDateString()}
              </p>
    
            </div>
          );
        } else {
          return (
            <div>
              <br />
              <h4>Choose before Pressing the Upload button</h4>
            </div>
          );
        }
      };

  const splitArray = (arr, chunkSize) => {
    let result = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      let chunk = arr.slice(i, i + chunkSize);
      result.push(chunk);
    }

    return result;
  }
       
  const hacerPeticiones = async () => {

    let counter = 0;

    const split = splitArray(respCall, 30)

    setStatesPrinter({currentPrinter:0,totalPrinter:respCall.length})

    for (const value of split) {

      try {


        // const data = {date_to:'1991-16-11',date_from:'1991-16-11',pvp:value[columnPrices.index],key:value[columnKey.index],typeKey:'reference'};
        const dataBody ={
            date_to:'1991-16-11'
            ,date_from:'1991-16-11'
            ,status:columnPrices.index
            ,key:columnKey.index
            ,typeKey:'reference'
            ,data:value
          };

        const response = await fetch(website, {
          method: 'POST', // o 'GET', 'PUT', 'DELETE', etc. según tu caso
          body: JSON.stringify(dataBody), // si necesitas enviar datos en el cuerpo de la solicitud
          mode: 'no-cors'
          // headers: {
            // 'Content-Type': 'application/json' // ajusta los encabezados según tus necesidades
          // }
        }).then(function (response) {

          counter = counter + value.length;
          setStatesPrinter({currentPrinter:counter,totalPrinter:respCall.length})

          if (!response.ok) {
            return response.text().then(result => Promise.reject(new Error(result)));
            // return response.text().then(result => Promise.reject(new Error(result)));
          }

          return response.json();

        }).then(function (data) {

          return data;

        })

        // const data2 = await response.json();
        
        
        // console.log(data2);

      } catch (error) {
        console.error(error);
      }
    }
  }


  const sendStatus = () => {

    hacerPeticiones()

  };

  const setColumn = (index,value) => {
    
    //document.querySelector(".selectorColumn0").value=='reference' 
    // setColumnPVP({columnKey : (value.target.value=='reference'?'reference':null), columnPrice : (value.target.value=='pvp'?'pvp':null)});
    if (value.target.value=='reference')
    {
      // setColumnKey(value.target.dataset['id']);
      setColumnKey(index);
    }

    if(value.target.value=='status')
    {
      // setColumnPVP(value.target.dataset['id']);
      setColumnPVP(index);
    }
    // value.preventDefault();
  };

  const optionsSelect = [
    { value: 'ingore', label: '(Ignore)' },
    { value: 'reference', label: 'Reference (SKU)' },
    { value: 'status', label: 'Status' }
  ]


  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
        </div>
    </>
    );
  };

  const ShowData = () => {

    
    
    // if (typeof respCall.length != undefined)
    // {
    //   return (
    //     <>
    //       {<Loading />}
    //     </>
    //   );
    // }
    

    return (
      <>
        <div className="table-responsive">
          <table className="table table-borderless mb-0" id="dataTable" width="100%" cellSpacing="0" role="grid" aria-describedby="dataTable_info">
            <thead className="border-bottom">
              <tr role="row">
                {respCall[0].map((value,index)=>{
                  return (
                    <>
                      <th>
                        {value.length==0?`(empty)`:value}

                        <select className={`form-control selectorColumn${index}`} onChange={(event)=>setColumn({index},event)}>
                          <option defaultValue="ignore" value="ignore">Ignore</option>
                          <option defaultValue="reference" value="reference">Reference</option>
                          <option defaultValue="status" value="status">Status</option>
                        </select>
                      </th>
                    </>
                  )
                })}
                <th>
                  <span className="btn btn-info uploadFile" onClick={() => sendStatus()}>Upload File</span>
                </th>
              </tr>
            </thead>
            <tbody>
            {respCall.slice(1).map((r,index0) => {
              return (
                <>
                  <tr>
                    {r.map((value,index1) => {
                      return (<><td>{value}</td></>)
                    })}
                    <td>
                      <span data-customid="index0">
                        
                      </span>
                    </td>
                  </tr>
                </>
              );
            })}
            </tbody>
          </table>
        </div>
      </>
    );
  };



  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar {...statesPrinter}/>
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Actualizar estado de productos
                                    </h1>
                                    <div className="page-header-subtitle">. </div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4"></div>
                            </div>
                        </div>
                    </div>
                </header>
                
                
                <div className="container-xl px-4 mt-n10">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                          <h6 className="m-0 font-weight-bold text-primary">Seleccione Sitio Web</h6>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div>
                                <select className="form-control" onChange={(event)=>refreshURL(event.target.value)}>
                                  <option>Seleccione una URL</option>
                                  {urlAvailables.map((value,index)=>{
                                    return <><option value={value.url} defaultValue={value.url}>{value.title} ({value.url})</option></>
                                  })}
                                </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                          <h6 className="m-0 font-weight-bold text-primary">Upload File XSLX</h6>
                      </div>
                      <div className="card-body">
                        <div className="row">

                          <div className="col-md-12">
                            <div>
                                <input type="file" onChange={(event)=>onFileChange(event)}/>
                                <button onClick={(event)=>onFileUpload()} className="btn btn-primary btn-sm">
                                  Upload!
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  


                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Información a cargar</h6>
                    </div>
                    <div className="card-body">
                      {loading1? <Loading /> : <ShowData /> }
                      
                    </div>
                  </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default ProductsActive;
