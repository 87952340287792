import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getOrderPendingToPrint, getOrders, noDataLoading, printLabelApi, printGuiaApi,getFileJSON,ApiSendPVP } from './Helper.js'
// import { ToastContainer, toast } from 'react-toastify';
// import { Select } from 'react-select';
// import 'react-toastify/dist/ReactToastify.css';

// import { Modal } from 'antd';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// CommonJS



import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

import {Link,useParams,useNavigate,useLocation} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Categories() {

  const Swal = require('sweetalert2');

  const parameterPage = useParams();
  const [respPrintDate,setRespPrintDate] = useState(true);
  const [respCall,setResponse] = useState(true);
  const [categoriesTree,setCategoriesTree] = useState(true);
  const [loading1, setLoading1] = useState(true);
  
  // const queryParams = new URLSearchParams(window.location.search)
  // const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []

  const [statesPrinter,setStatesPrinter] = useState(true);
  const [statesOptions,setStatesOptions] = useState(true);
  const [currentOrder,setCurrentOrder] = useState(true);
  const [columnPrices,setColumnPVP] = useState(true);
  const [columnKey,setColumnKey] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {

      const hash = location.hash;

      if (hash) {
        removeClass()
      }

    }, [location]);


  function removeClass() {
    const elementos = document.querySelectorAll('.rowCategoryTree');
    elementos.forEach(elemento => {
      elemento.classList.remove('highlight_categories');
    });
    addClass();
  }

  function addClass() {
    const hash = window.location.hash;
    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.classList.add('highlight_categories');
    }
  }


  useEffect(() => {
    document.title = "Digitag PS Master"
    setLoading1(true);
    setStatesPrinter({currentPrinter: 0,totalPrinter: 0});
    // setStatesOptions({0 : 'SKU',1 : 'ID PS',2 : 'PVP'});
    setCurrentOrder({id_order : 0, status : 'pending'});
    setColumnPVP(null);
    setColumnKey(null);
    setLoading1(false);
  }, []);

  // PAUSE PROCESS
  const elInput = document.querySelector('#pausePrint');
  let pause = Promise.resolve();
  const sleep = ms => new Promise(r => setTimeout(r,ms));

  const pauseClick = cb => 
    elInput.addEventListener('click', cb, {once: true});

  function checkForPause() {   
    pauseClick(() => {
      pause = new Promise(resolve => {
        pauseClick(() => { resolve(); checkForPause();});
      });
    });
  }
  // END PAUSE PROCESS

  const generateModal = (idCategory,categoryName) =>
  {
    Swal.fire({
      title: "<strong>"+categoryName+"</strong>",
      // icon: 'info',
      html:
        'You can use <b>bold text</b>, ' +
        '<a href="//sweetalert2.github.io">links</a> ' +
        'and other HTML tags',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
    })
  }
  const refreshData = () => 
  {

    // console.log(parameterPage);
      const refreshConst = async () => {
        setLoading1(true);
        setResponse(await getFileJSON('https://www.bubblegummers.cl/internal/api/query?id_query=58&token=9F2344F1AC9ADD40D4B2ADFA9280B18B6FA3F16634EE0B60F5B6758EEAE9CC71&debug'))
        setCategoriesTree(await getFileJSON('https://www.bubblegummers.cl/internal/api/query?id_query=59&token=9F2344F1AC9ADD40D4B2ADFA9280B18B6FA3F16634EE0B60F5B6758EEAE9CC71&debug'))
        setLoading1(false);
      };
      refreshConst();
  };
  useEffect(refreshData,[]);

  

  const emptyF = async (p1=null,p2=null,p3=null,p4=null,p5=null,p6=null,p7=null,p8=null) =>
  {
    return;
  }


  const printRange = async () => {
    
  };

  const removeDuplicates = (arr) => {
    return arr.reduce((acc, item) => {
      if (!acc.some((x) => x.id === item.id)) {
        acc.push(item);
      }
      return acc;
    }, []);
  };


  const deleteAssoc = async (event,idCategory,idProduct) => {

    const deleted = await getFileJSON(`https://www.bubblegummers.cl/internal/api/delete_assoc?id_category=${idCategory}&id_product=${idProduct}&token=9F2344F1AC9ADD40D4B2ADFA9280B18B6FA3F16634EE0B60F5B6758EEAE9CC71&debug`)

    if (deleted.status=='success')
    {
      const parentkey = event.target.dataset.parentkey;
      document.querySelector(`div[data-parentkey="${parentkey}"]`).remove();
    }

  };

  const sendPVP = async () => {

    setStatesPrinter({currentPrinter:0,totalPrinter:respCall.length-2})



    let counter = 0;
    respCall.slice(1).map((value,index)=>{

      if (value[columnKey.index]!="" && value[columnPrices.index]!="")
      {
        // const demo = ApiSendPVP('reference',value[columnKey.index],value[columnPrices.index],document.getElementById('date_from').value,document.getElementById('date_to').value,'https://ev.ps.digitag.cl/local/module/prestaclhook/pvp_price').then(function(response){
        const demo = ApiSendPVP('reference',value[columnKey.index],value[columnPrices.index],document.getElementById('date_from').value,document.getElementById('date_to').value,'https://ev.ps.digitag.cl/local/module/prestaclhook/pvp_price').then(function(response){

        }).then(function(response){
          setStatesPrinter({currentPrinter:counter,totalPrinter:respCall.length-2})
          counter ++;
        });
      }
    })
    


  };

  const setColumn = async (index,value) => {
    
    //document.querySelector(".selectorColumn0").value=='reference' 
    // setColumnPVP({columnKey : (value.target.value=='reference'?'reference':null), columnPrice : (value.target.value=='pvp'?'pvp':null)});
    if (value.target.value=='reference')
    {
      // setColumnKey(value.target.dataset['id']);
      setColumnKey(index);
    }

    if(value.target.value=='pvp')
    {
      // setColumnPVP(value.target.dataset['id']);
      setColumnPVP(index);
    }
    // value.preventDefault();
  };

  const optionsSelect = [
    { value: 'ingore', label: '(Ignore)' },
    { value: 'reference', label: 'Reference (SKU)' },
    { value: 'pvp', label: 'PVP' }
  ]


  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
        </div>
    </>
    );
  };

  // onClick={handleContainerClick}

  const handleContainerClick = (event) => {
    if (event.target.tagName === "SPAN") {
      const idCategory = event.target.getAttribute("data-id");
      const categoryName = event.target.getAttribute("data-name");
      generateModal(idCategory,categoryName)
    }
  };


  const ShowData = () => {

    
    
    // if (typeof respCall.length != undefined)
    // {
    //   return (
    //     <>
    //       {<Loading />}
    //     </>
    //   );
    // }
    

    return (
      <>
        <div className="table-responsive">
          <table className="table table-borderless mb-0" id="dataTable" width="100%" cellSpacing="0" role="grid" aria-describedby="dataTable_info">
            <thead className="border-bottom">
              <tr role="row">
                <th>
                  id_product
                </th>
                <th>
                  reference
                </th>
                <th>
                  product_name
                </th>
                <th>
                  default_category
                </th>
                <th>
                  categories
                </th>
                <th className="d-none">
                  <span className="btn btn-info uploadFile">view_product</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {respCall.map((value,index)=>{
                const categories = (JSON.parse(value.categories).categories); // removeDuplicates
                const processedCategories = {};
                return (
                  // onClick={() => generateModal.bind(category,category.id_category)}
                  <>
                    <tr>
                      <td>{value.id_product}</td>
                      <td>{value.reference}</td>
                      <td>{value.product_name}</td>
                      <td>{value.default_category}</td>
                      <td onClick={handleContainerClick}>
                        {categories.map((category, cindex) => {
                          if (processedCategories[category.id_category]) {
                            return null; // Salta al siguiente valor si el id_category ya ha sido procesado
                          }
                          processedCategories[category.id_category] = true; // Marca el id_category como procesado
                          return (
                            <>
                              <div data-parentkey={`${category.id_category}${value.id_product}`}>
                                <a className="btn btn-xs btn-info mb-1" data-id={`${category.id_category}${value.id_product}`} data-name={`${category.name_category}`} href={`#select_${category.id_category}`}>
                                  {category.id_category} - {category.name_category}
                                </a>

                                <a className="btn btn-xs btn-info mb-1" onClick={(event) => deleteAssoc(event, category.id_category, value.id_product)} data-parentkey={`${category.id_category}${value.id_product}`} data-category={`${category.id_category}`} data-product={`${value.id_product}`} data-name={`${category.name_category}`}>
                                  <svg data-parentkey={`${category.id_category}${value.id_product}`} className="deleteAssoc" width="10px" height="10px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#FFF" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"/></svg>
                                </a>
                                <br></br>
                              </div>
                            </>
                          );
                        })}

                      </td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };



  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar {...statesPrinter}/>
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Categorías
                                    </h1>
                                    <div className="page-header-subtitle">. </div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4"></div>
                            </div>
                        </div>
                    </div>
                </header>
                
                
                <div className="container-xl px-4 mt-n10">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">products_categories</h6>
                        </div>
                        <div className="card-body">
                          {loading1? <Loading /> : <ShowData /> }
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card shadow mb-4" style={{ position: 'fixed', right: 0, top: '0px', zIndex: 1000000, bottom: '-24px', 'max-width':'28%' , 'width':'100%' }}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">tree_categories</h6>
                        </div>
                        <div className="card-body" style={{flex: 'inherit', 'overflow-y': 'scroll'}}>
                          <div className="row">
                            <div className="col-md-12">
                              
                              {loading1? <Loading /> :
                              <>
                                {categoriesTree.map((value,index)=>{
                                  // const categories = JSON.parse(value.categories).categories;
                                  return (
                                    <>
                                    <div className="row rowCategoryTree" id={`select_${value.id_category}`}>
                                        <div className="col-md-2 idCategoryTree">{value.id_category}</div>
                                        <div className={`col-md-10 nameCategoryTree levelDepth_${value.level_depth}`}>
                                          {value.name}
                                        </div>
                                    </div>
                                    </>
                                  )
                                })}
                              </>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default Categories;
